
import { defineComponent } from "vue";
import PrescriberContent from "@/components/prescribers/PrescriberContent.vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Prescriber",
  components: {
    CardTitleJaya,
    PrescriberContent,
  },
  setup() {
    const router = useRouter();
    const prescriberId = Number(router.currentRoute.value.params.prescriberId);
    const mode = String(router.currentRoute.value.params.mode);
    return { prescriberId, mode };
  },
});
